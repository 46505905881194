<template>
  <div class="">
    <span v-if="ucsInsc.length > 0">
      <v-tooltip bottom color="white" open-delay="800" content-class="p-0 m-0 pb-2">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <span class="fs-10pt fw-600" v-if="ucs != undefined">{{ucsInsc.length}}</span>
            <span class="ms-1 fs-8pt fw-300" v-if="ucs != undefined">UCDs</span>
          </span>
        </template>
        <p class="m-0 p-0 mt-1 ms-3 f-poppins fs-9pt black-text">UCDs inscritas pelo candidato:</p>
        <v-divider class="m-0 p-0 mb-2"></v-divider>
        <v-card color="grey lighten-4" class="px-2 my-1 mx-2" v-for="(uc,key,index) in ucsInsc" :key="key">
          <v-container>
            <v-row align="center" justify="start">
              <v-col cols="auto" class="m-0 p-0">
                <v-icon class="material-icons-two-tone fs-12pt">school</v-icon>
              </v-col>
              <v-col cols="auto" class="m-0 p-0">
                <span class="ms-1 fs-9pt fw-500 f-lato">{{uc.nome}}</span>
              </v-col>
              <v-col cols="auto" class="m-0 p-0 px-1">
                <span v-for="(area, key, index) in uc.areas" :key="key">
                  <tagarea class="me-1" :sigla="area.sigla"/>
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tooltip>
    </span>
    <span v-if="ucsInsc.length == 0" class="fw-200">não indicado</span>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "tooltipucs",
  components: {
    'tagarea': () => import("./inscricaoUCD/tagArea.vue"),
  },
  props: {
    ucs: { default: null, type: Object }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      ucsInsc: [],
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
      this.ucsInsc = [];
      if(this.ucs != null) {
        for(var key in this.ucs) {
          rdb.ref('/curriculo/ucs/'+key).once('value',function(snapshot) {
            var uc = snapshot.val();
            self.ucsInsc.push(uc);
          });
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
